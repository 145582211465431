import { Box, Grid, NavSidebar, Typography } from "@enerbit/base";
import { pathOptions } from "../const/path";
import CardPath from "../components/CardPath";

const FrontiersCgmLobby = () => {
  return (
    <>
      <NavSidebar />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "calc(99.6vw - 70px)",
          top: "70px",
          left: "70px",
          height: "calc(100vh - 70px)",
          backgroundColor: "#F5F5F5",
        }}>
        <Box sx={{ zIndex: "0", width: "100%", overflowY: "scroll", py: "10px", px: "60px" }}>
          <Typography color='primary' variant='h1' sx={{ fontSize: "34px", fontWeight: "bold" }}>
            Fronteras y CGM
          </Typography>
          <Grid container columnSpacing={3} rowSpacing={3}>
            {pathOptions?.map((card, index) => (
              <Grid key={index} item xs={6}>
                <CardPath card={card} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default FrontiersCgmLobby;
